<template>
  <div>    
    <DomiciliaryCare />
  </div>
</template>
<script>
import DomiciliaryCare from "../components/DomiciliaryCare";
export default {
  components: {    
    DomiciliaryCare,
  },
};
</script>