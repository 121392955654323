<template>
  <div class="service-area pt--70 pb--50 bg_color--1">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-center section-title mb--30">
            <h2 class="heading-title">
              <span class="app-title-text-left">Domiciliary</span> Care
            </h2>
            <p class="description">
              Personally tailored to You. We provide person-centred home care
              services that are tailored to your needs.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="service-main-wrapper bg-white--service">
        <v-col
          lg="4"
          md="4"
          sm="6"
          cols="12"
          v-for="(listGroup, i) in listInfo"
          :key="i"
        >
          <div class="service service__style--2 text-left bg-gray">
            <div class="service">
              <div class="icon">
                <div v-html="iconSvg(listGroup.icon)"></div>
              </div>
              <div class="content">
                <h3 class="heading-title">{{ listGroup.title }}</h3>
                <p v-html="listGroup.text"></p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      listInfo: [
        {
          icon: "home",
          title: "Holiday Care",
          text: `If you are thinking of taking a break away from home, our care service can provide you with the support that you need during time away in the UK or abroad.`,
        },
        {
          icon: "layers",
          title: "Hospital to Home",
          text: `We can provide support for people being discharged from hospital. Whether it is through the entire process or if you are returning home, we can help.`,
        },
        {
          icon: "users",
          title: "Live-in Care",
          text: `With our live-in care service, a Carer or Support Worker can move into your home with you to support you to remain living independently.`,
        },
        {
          icon: "users",
          title: "Night Care",
          text: `We provide night care and support in many situations from sleeping nights to waking night service. For reassurance or hands-on care, we can be by your bedside.`,
        },
        {
          icon: "users",
          title: "Palliative Care",
          text: `For patients suffering from a life-threatening illness, our palliative care service can support you to remain in the comfort of your own home, whatever your requirements.`,
        },
        {
          icon: "users",
          title: "Personal Care",
          text: `Our discreet personal care service, is designed to support you with day-to-day living, allowing you to remain independent in your own home.`,
        },
        {
          icon: "users",
          title: "Respite Care",
          text: `As with our other care services, respite care can provide support for various conditions including Alzheimer’s, Multiple Sclerosis, Strokes, Cancer, and more.`,
        },

        {
          icon: "users",
          title: "Specialist Care",
          text: `We will develop a comprehensive person-centred plan of care based on the identified needs. Your care plan is regularly reviewed to ensure its suitability to your health and social care needs.`,
        },
        {
          icon: "users",
          title: "Social Companionship",
          text: `Whether it be a company on outings, support with household chores or simply someone to have a cup of tea and a chat with, our service can meet the simplest of requirements.`,
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
